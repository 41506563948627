export default {
  ctaButtonBackground: 'var(--rahoitu-orange-500)',
  buttonBorderRadius: '44px',
  buttonBorderRadiusLead: '0.25rem',
  buttonPadding: '1.5rem',
  disabledBg: 'var(--sg-grey-300)',
  disabledColor: 'var(--sg-grey-500)',
  fontWeight: 'bold',
  secondaryButtonBorder: '1px solid var(--black)',
  ctaButtonColor: 'var(--black)',
};
