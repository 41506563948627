import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import devices from '../../../../../js/styles/Devices';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';
import Tooltips from '../../../../utils/tooltips';
import store from '../../../../../js/store';
import SalarySuggestion from '../../../../utils/SalarySuggestion';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    display: flex;
    position: relative;
    flex-direction: column;
    font-family: var(--font-family);
    color: var(--black);
    font-size: 1rem;
    font-weight: 400;

    ${(props) => props.storeValueDisplay && css`
      margin: 1.5rem 0 1rem 0;

    @media ${devices.downFromDesktop} {
      margin: 0 0 1rem 0;
    }
  `}
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
  }
`;

const Label = styled.label`
  && {
    position: relative;
    z-index: 1;
    padding: 0 0 0.625rem;
  }
`;

const Input = styled.input`
  background: var(--white);
  border: 0;
  -ms-flex: 1 1;
  flex: 1 1;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: 0.625rem 1rem;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--black);
  width: 100%;
  background-size: 1.5rem;
  background-position: 98%;
  background-repeat: no-repeat;

  ${(props) => props.valid && props.validated && css`
    background-image: url(https://no-digifinans-production-cms-uploadbucket-14u89bxmjgysi.s3.amazonaws.com/uploads/2023/04/green_checkmark.svg);
  `}

  ${(props) => (props.validated && props.valid === false) && css`
   background-image: url(https://no-digifinans-production-cms-uploadbucket-14u89bxmjgysi.s3.amazonaws.com/uploads/2023/04/warning_icon.svg);
  `}

`;

const Field = styled.div`
  align-items: center;
  background: var(--white);
  border: 0;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--dark-teal-20);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  cursor: text;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  ${(props) => props.storeValueDisplay && css`
     border-radius: 20px;
     border-color: var(--sg-grey-500);
  `}

  ${(props) => props.valid && props.validated && css`
    border: 2px solid var(--rahalaitos-green-400);
  `}

  ${(props) => (props.validated && props.valid === false) && css`
    border: 2px solid var(--digi-red-400);
  `}

  ${(props) => props.valid === null && css`
    border-color: var(--black);
  `}
`;

const Suffix = styled.div`
  && {
    color: var(--black);
    font-weight: 500;
    padding-right: 0.5rem;
  }
`;

Label.displayName = 'label';

const TextInput = ({
  intl: { formatMessage },
  id,
  name,
  compact,
  labelOutside,
  label,
  placeholder,
  suffix,
  removeSuffix,
  validated,
  storeValueDisplay,
  valid,
  className,
  margin,
  tooltip,
  tooltipLink,
  whitelist,
  disableButton,
  submitSuccess,
  expandedForm,
  setShowErrors,
  passedRef,
  changeField,
  descriptionText,
  inputRef,
  contactForm,
  isNumber,
  englishSuffix,
  totalAmountField,
  repaymentTimeInput,
  maxValue,
  ...rest
}) => {
  // eslint-disable-next-line
  const inputId = id || `text-input--${name || ''}--${label.replace(/\s/, '-')}`;

  const WebsiteLink = (chunks) => <a href={tooltipLink} target="_blank" rel="noreferrer">{chunks}</a>;

  const fieldNames = [
    'applicant.otherMonthlyIncomeBeforeTax',
    'applicant.spouseMonthlyIncomeBeforeTax',
    'applicant.monthlyIncomeBeforeTax',
    'applicant.monthlyIncomeAfterTax',
    'coApplicant.monthlyIncomeBeforeTax',
    'coApplicant.monthlyIncomeAfterTax',
    'coApplicant.otherMonthlyIncomeBeforeTax',
  ];

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') {
    tooltipObject.text = formatMessage(tooltip.text, {
      a: WebsiteLink,
    });
  }

  const state = store.getState();

  useEffect(() => {
    if (maxValue) {
      const value = Number(state.form.application.values[name]);
      if (value > maxValue) {
        String(state.form.application.values[name] = maxValue);
      }
    }
  }, [ state, maxValue]); // eslint-disable-line

  return (
    <Wrapper
      storeValueDisplay={storeValueDisplay}
      totalAmountField={totalAmountField}
      repaymentTimeInput={repaymentTimeInput}
      margin={margin}
    >
      {label && (contactForm || label.id) && (
      <LabelWrapper>
        <Label htmlFor={inputId}>
          {contactForm ? label : formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips
              tooltipObject={tooltipObject}
            />
          )}
      </LabelWrapper>
      )}
      <Field
        storeValueDisplay={storeValueDisplay}
        validated={validated}
        totalAmountField={totalAmountField}
        repaymentTimeInput={repaymentTimeInput}
        valid={valid}
      >
        {(placeholder || contactForm) && (placeholder.id || contactForm) && (
          <Input
            validated={validated}
            valid={valid}
            ref={inputRef}
            id={inputId}
            storeValueDisplay={storeValueDisplay}
            name={name}
            placeholder={contactForm ? placeholder : formatMessage(placeholder)}
            data-hj-whitelist={whitelist || null}
            tabIndex="0"
            totalAmountField={totalAmountField}
            repaymentTimeInput={repaymentTimeInput}
            {...rest}
          />
        )}
        { suffix && !removeSuffix && (
        <Suffix>
          <span>{formatMessage(suffix)}</span>
        </Suffix>
        )}
      </Field>
      {fieldNames.map((fieldName) => {
        if (String(name) === fieldName) {
          return <SalarySuggestion fieldName={name} key={name} />;
        }
        return null;
      })}
    </Wrapper>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  storeValueDisplay: PropTypes.bool,
  intl: intlShape.isRequired,
  type: PropTypes.oneOf(['text', 'tel', 'email', 'password', 'url', 'search']),
  name: PropTypes.string,
  compact: PropTypes.bool,
  labelOutside: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
  }),
  suffix: PropTypes.shape(),
  englishSuffix: PropTypes.bool,
  removeSuffix: PropTypes.bool,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  maxValue: PropTypes.number,
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  override: PropTypes.shape({
    input: PropTypes.shape({
      className: PropTypes.string,
      labelClassName: PropTypes.string,
    }),
  }),
  whitelist: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
  ]),
  contactForm: PropTypes.bool,
  placeholder: PropTypes.shape(),
  margin: PropTypes.string,
  isNumber: PropTypes.bool,
  disableButton: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  expandedForm: PropTypes.bool,
  setShowErrors: PropTypes.func,
  passedRef: PropTypes.shape(),
  changeField: PropTypes.func,
  descriptionText: PropTypes.string,
  totalAmountField: PropTypes.bool,
  repaymentTimeInput: PropTypes.bool,
};

TextInput.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  maxValue: null,
  storeValueDisplay: false,
  compact: false,
  labelOutside: false,
  label: {},
  suffix: '',
  englishSuffix: false,
  removeSuffix: false,
  validated: false,
  valid: null,
  className: '',
  tooltip: '',
  tooltipLink: '',
  override: {
    input: {},
  },
  whitelist: false,
  inputRef: null,
  contactForm: false,
  placeholder: {},
  margin: '',
  isNumber: false,
  disableButton: false,
  submitSuccess: false,
  expandedForm: false,
  setShowErrors: () => {},
  passedRef: {},
  changeField: () => {},
  descriptionText: '',
  totalAmountField: false,
  repaymentTimeInput: false,
};

export default injectIntl(TextInput);

