import LOAN_PURPOSES from '../../../../localizations/config/FI/shared/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/FI/shared/employment-types';
import HOME_TYPES from '../../../../localizations/config/FI/shared/home-types';
import MARITAL_STATUS from '../../../../localizations/config/FI/shared/marital-status';
import DEPENDANTS from '../../../../localizations/config/FI/shared/dependants';
import EDUCATION_TYPES from '../../../../localizations/config/FI/shared/education-types';
import MILITARY_TYPES from '../../../../localizations/config/FI/shared/military-types';
import OCCUPATION_CATEGORY from '../../../../localizations/config/FI/shared/occupation-category';
import CITIZENSHIPS from '../../../../localizations/config/FI/shared/citizenships';
import HOME_TYPE_CATEGORIES from '../../../../localizations/config/FI/shared/home-type-categories';
import LOAN_TYPES from '../../../../localizations/config/FI/omalaina/loan-types';

import {
  mergeLoan,
  dependants,
  purpose,
  homeType,
  civilStatus,
  homeCost,
  employmentType,
  numbersZeroAndEmpty,
  employerName,
  otherCost,
} from '../../../../validators';

import { validateMonthlySalaryBeforeTax, spouseMonthlyIncomeBeforeTaxValidator } from '../../../../validators/FI/monthlySalaryBeforeTax';
import email from '../../../../validators/FI/email';
import monthlySalaryAfterTax from '../../../../validators/FI/monthlySalaryAfterTax';
import otherIncomeBeforeTax from '../../../../validators/otherIncomeBeforeTax';
import { validateEmploymentSinceYear, validateEmploymentSinceMonth } from '../../../../validators/employedSince';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';
import validateTotalLoan from '../../../../validators/totalLoan';
import validateRepaymentTime from '../../../../validators/repaymentTime';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';

import { validateEmploymentToYear, validateEmploymentToMonth } from '../../../../validators/employedTo';
import { validateRequiredButtonGroup, validateRequiredCheckbox } from '../../../../validators/requiredButtonGroup';
import validateRequiredTextInput from '../../../../validators/requiredTextInput';
import validateNationalId from '../../../../validators/FI/nationalId';
import validateBankAccount from '../../../../validators/FI/bankAccount';

import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';
import submit from '../../../../mappers/FI/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
    userAgreement: 'https://www.new.rahoitu.fi/kayttajaehdot/',
    dataPolicy: 'https://www.new.rahoitu.fi/tietosuojaseloste/',
    dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
  },
  slides: [
    {
      title: textResource.slideTitleOne,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.applicationLoanAmountLabel,
            showLabel: true,
            name: 'totalAmount',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: 500,
                max: 60000,
              },
              step: LOAN_LIMITS.STEP,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'totalAmount',
            fontWeight: 'bold',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              maxValue: 60000,
              validate: validateTotalLoan,
              validateOnRegister: true,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.applicationLoanAmountPlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            type: 'tel',
            lead: true,
            showLabel: true,
            label: textResource.repaymentTimeLabel,
            sliderConfig: {
              range: {
                min: 1,
                max: 20,
              },
            },
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'repaymentTime',
              type: 'tel',
              validate: validateRepaymentTime,
              validateOnRegister: true,
              textAlign: '',
              maxValue: 20,
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              placeholder: textResource.repaymentTimePlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            approximateMonthlyCostMobile: textResource.approximateMonthlyCostMobile,
            repaymentTimeLimit: '>5',
            minimal: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            tooltip: tooltip(textResource.phoneNumberTooltip),
            label: textResource.phoneNumberLabel,
            placeholder: textResource.phoneNumberPlaceholder,
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            hasValidateFunction: true,
            validate: email,
            lead: true,
            layout: {
              start: 4,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            name: 'purpose',
            label: textResource.purposeHeaderText,
            placeholder: textResource.purposeHeaderText,
            hasValidateFunction: true,
            validate: purpose,
            buttons: LOAN_PURPOSES,
            tooltip: tooltip(textResource.purposeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'civilStatus',
            label: textResource.civilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.civilStatusTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'dependants',
            label: textResource.dependantsLabel,
            hasValidateFunction: true,
            buttonLayout: 'compact',
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.dependantsTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'citizenship',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.citizenshipLabel,
            buttons: CITIZENSHIPS,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'nationalId',
            label: textResource.nationalIdLabel,
            validate: validateNationalId,
            placeholder: textResource.nationalIdPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.nationalIdTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.samblaPlusText,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleTwo,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            placeholder: textResource.employmentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.employmentTypeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'employmentSince',
            label: textResource.employmentSinceYearLabel,
            layout: {
              start: 1,
              end: 3,
            },
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceYearDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'applicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],

              },
            ],
            placeholder: textResource.employmentSinceYearPlaceholder,
            validate: validateEmploymentSinceYear,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.employmentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            label: textResource.employmentSinceMonthLabel,
            layout: {
              start: 3,
              end: 5,
            },
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employedSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.employmentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employmentTo',
            label: textResource.employedToYearLabel,
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.employedToYearPlaceholder,
            tooltip: tooltip(textResource.employedToTooltip),
            layout: {
              start: 1,
              end: 3,
            },
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.applicant.employmentType === "parentalLeave");',
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.employedToMonthLabel,
            validate: validateEmploymentToMonth,
            hasValidateFunction: true,
            placeholder: textResource.employedToMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employerName',
            validate: employerName,
            hasValidateFunction: true,
            label: textResource.employerNameLabel,
            placeholder: textResource.employerNamePlaceholder,
            layout: {
              start: 1,
              end: 3,
            },
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employerNameLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedEmployerNameDynamicLabel,
                  },
                ],
              },
            ],
            tooltip: tooltip(textResource.employerNameTooltip),
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            name: 'occupationTitle',
            label: textResource.occupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            layout: {
              start: 3,
              end: 5,
            },
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            name: 'occupationCategory',
            label: textResource.occupationCategoryLabel,
            buttons: OCCUPATION_CATEGORY,
            layout: {
              start: 1,
              end: 7,
            },
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            label: textResource.monthlyIncomeBeforeTaxLabel,
            placeholder: textResource.monthlyIncomeBeforeTaxPlaceholder,
            validate: validateMonthlySalaryBeforeTax,
            hasValidateFunction: true,
            type: 'tel',
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            label: textResource.monthlyIncomeAfterTaxLabel,
            placeholder: textResource.monthlyIncomeAfterTaxPlaceholder,
            validate: monthlySalaryAfterTax,
            hasValidateFunction: true,
            type: 'tel',
            layout: {
              start: 4,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'spouseMonthlyIncomeBeforeTax',
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            isNumber: true,
            hasValidateFunction: true,
            label: textResource.spouseMonthlyIncomeLabel,
            placeholder: textResource.spouseMonthlyIncomePlaceholder,
            type: 'tel',
            visibleWhen: 'return (formState.values.applicant.civilStatus && (formState.values.applicant.civilStatus === "married" || formState.values.applicant.civilStatus === "partner"));',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'otherIncome',
            label: textResource.otherIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            layout: {
              start: 1,
              end: 7,
            },
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.otherIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            isNumber: true,
            name: 'otherMonthlyIncomeBeforeTax',
            placeholder: textResource.grossOtherIncomePlaceholder,
            validate: otherIncomeBeforeTax,
            hasValidateFunction: true,
            label: textResource.grossOtherIncomeLabel,
            type: 'tel',
            visibleWhen: 'return (formState.values.applicant.otherIncome !== undefined && formState.values.applicant.otherIncome === true);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleThree,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'educationLevel',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.educationLabel,
            placeholder: textResource.educationLabel,
            buttons: EDUCATION_TYPES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'militaryServiceStatus',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.militaryTypeLabel,
            buttons: MILITARY_TYPES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'homeType',
            label: textResource.homeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            buttons: HOME_TYPES,
            tooltip: tooltip(textResource.homeTypeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.homeTypeCategoryDescriptionLabel,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttons: HOME_TYPE_CATEGORIES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabel,
            placeholder: textResource.moveInDatePlaceholder,
            validate: validateEmploymentSinceYear,
            hasValidateFunction: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.homeCostLabel,
            validate: homeCost,
            placeholder: textResource.homeCostPlaceholder,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.homeCostTooltip),
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.otherLivingCostLabel,
            placeholder: textResource.otherLivingCostPlaceholder,
            tooltip: tooltip(textResource.otherLivingCostTooltip),
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleFour,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'FormHeadline',
          props: {
            label: textResource.debtBreakdownLabel,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'applicant',
            addButtonLabel: textResource.loanAdd,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                placeholder: textResource.civilStatusPlaceholder,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SimpleValueLabel',
          props: {
            suffix: textResource.loanCalculatorCurrencySuffix,
            value: 'applicant.currentLoans.refinanceSum',
            label: textResource.loansSummary,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.bankAccountHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          props: {
            name: 'bankAccount',
            type: 'text',
            convertToUpperCase: true,
            label: textResource.bankAccountLabel,
            placeholder: textResource.bankAccountPlaceholder,
            tooltip: tooltip(textResource.bankAccountTooltip),
            hasValidateFunction: true,
            validate: validateBankAccount,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.addCoApplicantLabel,
            tooltip: tooltip(textResource.addCoApplicantTooltip),
            transparent: true,
            header: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            label: textResource.addCoApplicantDesc,
            name: 'hasCoApplicant',
            rightPadding: true,
            layout: {
              start: 1,
              end: 7,
            },
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            tooltip: tooltip(textResource.addCoApplicantTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'coApplicant',
          form: 'ButtonGroup',
          props: {
            name: 'politicallyExposedPerson',
            rightPadding: true,
            label: textResource.isCoApplicantPepLabel,
            tooltip: tooltip(textResource.isCoApplicantPepTooltip),
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'CreditCheckConsent',
          form: 'Checkbox',
          props: {
            name: 'creditCheckConsent',
            text: textResource.acceptCreditCheck,
            linkText: textResource.samblaPlusReadMore,
            links: 'https://www.new.rahoitu.fi/kayttajaehdot/',
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: false,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.pCRText,
            transparent: true,
            margin: false,
            center: false,
            visibleWhen: 'return (formState.values.hasCoApplicant !== true);',
            PCR: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleFive,
      coApplicantSlide: true,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'phoneNumber',
            label: textResource.coApplicantPhoneNumberLabel,
            placeholder: textResource.coApplicantPhoneNumberPlaceholder,
            validate: validatePhoneNumberFi,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantPhoneNumberTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'coApplicant',
          props: {
            name: 'email',
            label: textResource.coApplicantEmailLabel,
            placeholder: textResource.coApplicantEmailPlaceholder,
            validate: email,
            tooltip: tooltip(textResource.coApplicantEmailTooltip),
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'nationalId',
            label: textResource.coApplicantNationalIdLabel,
            placeholder: textResource.coApplicantNationalIdPlaceholder,
            validate: validateNationalId,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantNationalIdTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'civilStatus',
            label: textResource.coApplicantCivilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.coApplicantCivilStatusTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'dependants',
            label: textResource.coApplicantDependantsLabel,
            hasValidateFunction: true,
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.coApplicantDependantsTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'educationLevel',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.coApplicantEducationLabel,
            placeholder: textResource.coApplicantEducationLabel,
            buttons: EDUCATION_TYPES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'citizenship',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.coApplicantCitizenshipLabel,
            buttons: CITIZENSHIPS,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'militaryServiceStatus',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.coApplicantMilitaryTypeLabel,
            buttons: MILITARY_TYPES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentType',
            label: textResource.coApplicantEmploymentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.coApplicantEmploymentTypeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSince',
            validate: validateEmploymentSinceYear,
            label: textResource.coApplicantEmploymentSinceYearLabel,
            layout: {
              start: 1,
              end: 3,
            },
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'coApplicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.coApplicantEmploymentSinceYearPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            layout: {
              start: 3,
              end: 5,
            },
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceMonthDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceMonthDynamicLabel,
                  },
                ],
              },
            ],
            label: textResource.coApplicantEmploymentSinceMonthLabel,
            placeholder: textResource.coApplicantEmploymentSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.coApplicantEmploymentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentTo',
            layout: {
              start: 1,
              end: 3,
            },
            label: textResource.coApplicantEmployedToYearLabel,
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.coApplicantEmployedToYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployedToTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            layout: {
              start: 3,
              end: 5,
            },
            name: 'employmentToMonth',
            label: textResource.coApplicantEmployedToMonthLabel,
            validate: validateEmploymentToMonth,
            hasValidateFunction: true,
            placeholder: textResource.employedToMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employerName',
            layout: {
              start: 1,
              end: 3,
            },
            validate: employerName,
            hasValidateFunction: true,
            sectionName: 'coApplicant',
            label: textResource.coApplicantEmployerNameLabel,
            placeholder: textResource.coApplicantEmployerNamePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployerNameTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType !== undefined && formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            name: 'occupationTitle',
            label: textResource.coApplicantOccupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            layout: {
              start: 3,
              end: 5,
            },
            visibleWhen: 'return (formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            hasValidateFunction: true,
            layout: {
              start: 1,
              end: 7,
            },
            validate: validateRequiredButtonGroup,
            name: 'occupationCategory',
            label: textResource.coApplicantOccupationPlaceholder,
            buttons: OCCUPATION_CATEGORY,
            visibleWhen: 'return (formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            label: textResource.coApplicantMonthlyIncomeBeforeTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeBeforeTaxPlaceholder,
            validate: validateMonthlySalaryBeforeTax,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantMonthlyIncomeBeforeTaxTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            label: textResource.coApplicantMonthlyIncomeAfterTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeAfterTaxPlaceholder,
            validate: monthlySalaryAfterTax,
            hasValidateFunction: true,
            type: 'tel',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'otherIncome',
            label: textResource.coApplicantOtherIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            layout: {
              start: 1,
              end: 7,
            },
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.coApplicantOtherIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            isNumber: true,
            name: 'otherMonthlyIncomeBeforeTax',
            placeholder: textResource.coApplicantGrossOtherIncomePlaceholder,
            validate: otherIncomeBeforeTax,
            hasValidateFunction: true,
            label: textResource.coApplicantGrossOtherIncomeLabel,
            type: 'tel',
            visibleWhen: 'return (formState.values.coApplicant.otherIncome !== undefined && formState.values.coApplicant.otherIncome === true);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'spouseMonthlyIncomeBeforeTax',
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            isNumber: true,
            hasValidateFunction: true,
            label: textResource.coApplicantSpouseMonthlyIncomeLabel,
            placeholder: textResource.coApplicantSpouseMonthlyIncomePlaceholder,
            type: 'tel',
            layout: {
              start: 1,
              end: 3,
            },
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.civilStatus && (formState.values.coApplicant.civilStatus === "married" || formState.values.coApplicant.civilStatus === "partner"));',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'homeType',
            label: textResource.coApplicantHomeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            tooltip: tooltip(textResource.coApplicantHomeTypeTooltip),
            buttons: HOME_TYPES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.coApplicantHomeTypeCategoryDescriptionLabel,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttons: HOME_TYPE_CATEGORIES,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabelCoApplicant,
            placeholder: textResource.moveInDatePlaceholderCoApplicant,
            validate: validateEmploymentSinceYear,
            hasValidateFunction: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.coApplicantHomeCostLabel,
            placeholder: textResource.coApplicantHomeCostPlaceholder,
            validate: homeCost,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantHomeCostTooltip),
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.coApplicantOtherLivingCostsLabel,
            placeholder: textResource.coApplicantOtherLivingCostPlaceholder,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            tooltip: tooltip(textResource.coApplicantOtherLivingCostsTooltip),
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.debtBreakdownLabel,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'coApplicant',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            addButtonLabel: textResource.loanAdd,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                placeholder: textResource.civilStatusPlaceholder,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SimpleValueLabel',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            suffix: textResource.loanCalculatorCurrencySuffix,
            value: 'applicant.currentLoans.refinanceSum',
            label: textResource.loansSummary,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'coApplicant',
          form: 'ButtonGroup',
          props: {
            label: textResource.isCoApplicantPepLabel,
            tooltip: tooltip(textResource.isCoApplicantPepTooltip),
            name: 'politicallyExposedPerson',
            layout: {
              start: 1,
              end: 7,
            },
            rightPadding: true,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.pCRText,
            transparent: true,
            margin: false,
            center: false,
            PCR: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
