import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';

const OCCUPATION_CATEGORY = [
  {
    label: textResource.occupationCategoryEntrepreneur,
    value: 'entrepreneur',
  },
  {
    label: textResource.occupationCategorySeniorClerk,
    value: 'seniorClerk',
  },
  {
    label: textResource.occupationCategoryLowerEmployee,
    value: 'lowerEmployee',
  },
  {
    label: textResource.occupationCategoryEmployee,
    value: 'employee',
  },
  {
    label: textResource.occupationCategoryFarmer,
    value: 'farmer',
  },
  {
    label: textResource.occupationCategoryLeadership,
    value: 'leadership',
  },
  {
    label: textResource.occupationCategoryOther,
    value: 'other',
  },
];
export default OCCUPATION_CATEGORY;
