import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';

const HOME_TYPES = [
  {
    label: textResource.homeTypeRentedApartment,
    value: 'rentedApartment',
  },
  {
    label: textResource.homeTypeVilla,
    value: 'villa',
  },
  {
    label: textResource.homeTypeCondominium,
    value: 'condominium',
  },
  {
    label: textResource.homeTypeParents,
    value: 'parents',
  },
  {
    label: textResource.homeTypeOther,
    value: 'other',
  },
];

export default HOME_TYPES;
