export default {
  gridTemplate: ' 1fr 1fr 1fr 1fr 1fr 1fr',
  gridDefaultStart: '1',
  gridDefaultEnd: '2',
  gridColumnGap: '0.5rem',
  gridMarginRight: '1rem',
  formTextColor: 'var(--black)',
  loanCalculatorPadding: '1.5rem',
  firstBottomButtonEnd: 7,
  secondBottomButtonEnd: 7,
  submitButtonEnd: 7,
  nextButtonOrder: -1,
};
