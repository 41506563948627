import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';

const LOAN_PURPOSES = [
  {
    label: textResource.loanPurposeConsolidateLoans,
    value: 'consolidateLoans',
  },
  {
    label: textResource.loanPurposeEverydayExpenses,
    value: 'everydayExpenses',
  },
  {
    label: textResource.loanPurposeRenovation,
    value: 'renovation',
  },
  {
    label: textResource.loanPurposeVehicles,
    value: 'vehicle',
  },
  {
    label: textResource.loanPurposeTravel,
    value: 'travel',
  },
  {
    label: textResource.loanPurposeHealth,
    value: 'health',
  },
  {
    label: textResource.loanPurposeServices,
    value: 'services',
  },
  {
    label: textResource.loanPurposeBusiness,
    value: 'business',
  },
  {
    label: textResource.loanPurposeInvestment,
    value: 'investment',
  },
  {
    label: textResource.loanPurposeStudies,
    value: 'studies',
  },
  {
    label: textResource.loanPurposeOther,
    value: 'other',
  },
];

export default LOAN_PURPOSES;
